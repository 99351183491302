<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Account"
              invalid-feedback="Account is required."
              ref="acc"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="accList"
                :reduce="(opt) => opt.id"
                label="title"
                v-model="myObj.accountID"
                placeholder="Select account"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Cheque No. Start"
              invalid-feedback="Cheque No. Start is required."
              ref="start"
            >
              <b-form-input
                type="number"
                v-model="myObj.start"
                placeholder="Enter cheque no. start"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Cheque No. End"
              invalid-feedback="Cheque No. End is required."
              ref="end"
            >
              <b-form-input
                type="number"
                v-model="myObj.end"
                placeholder="Enter cheque no. end"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Total Leafs"
              invalid-feedback="Total Leafs is required."
              ref="total"
            >
              <b-form-input
                type="number"
                v-model="myObj.totalLeafs"
                placeholder="Enter total leafs"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Remaining Leafs"
              invalid-feedback="Remaining Leafs is required."
              ref="remain"
            >
              <b-form-input
                type="number"
                v-model="myObj.remainingLeafs"
                placeholder="Enter remaining leafs"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Last Cheque Issued"
              invalid-feedback="Last Cheque Issued is required."
              ref="last"
            >
              <b-form-input
                type="number"
                v-model="myObj.lastIssued"
                placeholder="Enter last cheque issued"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              variant="success"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row>
        <b-col xl="2" lg="3" md="4">
          <b-button
            @click="AddOpen()"
            block
            variant="primary"
            class="mb-1 mb-md-0"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Cheque Book</span>
          </b-button>
        </b-col>
        <!-- <b-col xl="10" lg="9" md="8">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col> -->
      </b-row>

      <b-table
        class="mt-2"
        :items="items"
        :fields="fields"
        :busy="dataLoading"
        responsive
        show-empty
      >
        <template #table-busy>
          <div class="d-flex justify-content-center my-2">
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(start)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(end)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(totalLeafs)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(lastIssued)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-1"
              @click="Edit(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BSpinner,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BSpinner,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BSidebar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      dataLoading: false,
      items: [],
      fields: [
        { label: "start", key: "start" },
        { label: "end", key: "end" },
        { label: "total", key: "totalLeafs" },
        { label: "last issued", key: "lastIssued" },
        { label: "actions", key: "actions" },
      ],
      myObj: {
        id: 0,
        start: 0,
        end: 0,
        accountID: 0,
        lastIssued: 0,
        remainingLeafs: 0,
        totalLeafs: 0,
        campusID: this.$store.state.userData.cId,
      },
      searchQuery: "",
      accList: [],
      sidebarTitle: "",
      visibility: false,
      request: false,
    };
  },
  created() {
    // this.LoadData();
    this.LoadAccounts();
  },
  computed: {},
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    AddOpen() {
      this.myObj = {
        id: 0,
        start: 0,
        end: 0,
        accountID: 0,
        lastIssued: 0,
        remainingLeafs: 0,
        totalLeafs: 0,
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add Cheque Book";

      var elem = this.$refs["acc"];
      elem.state = undefined;
      var elem = this.$refs["start"];
      elem.state = undefined;
      var elem = this.$refs["end"];
      elem.state = undefined;
      var elem = this.$refs["total"];
      elem.state = undefined;
      var elem = this.$refs["remain"];
      elem.state = undefined;
      var elem = this.$refs["last"];
      elem.state = undefined;
    },
    Edit(item) {
      this.myObj = { ...item };
      this.visibility = true;
      this.sidebarTitle = "Add Cheque Book";

      var elem = this.$refs["acc"];
      elem.state = undefined;
      var elem = this.$refs["start"];
      elem.state = undefined;
      var elem = this.$refs["end"];
      elem.state = undefined;
      var elem = this.$refs["total"];
      elem.state = undefined;
      var elem = this.$refs["remain"];
      elem.state = undefined;
      var elem = this.$refs["last"];
      elem.state = undefined;
    },

    CheckAccount() {
      var elem = this.$refs["acc"];
      if (this.myObj.accountID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckStart() {
      var elem = this.$refs["start"];
      let x = parseInt(this.myObj.start);
      if (isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        this.myObj.start = x;
        return (elem.state = true);
      }
    },
    CheckEnd() {
      var elem = this.$refs["end"];
      let x = parseInt(this.myObj.end);
      if (isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        this.myObj.end = x;
        return (elem.state = true);
      }
    },
    CheckTotal() {
      var elem = this.$refs["total"];
      let x = parseInt(this.myObj.totalLeafs);
      if (isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        this.myObj.totalLeafs = x;
        return (elem.state = true);
      }
    },
    CheckRemain() {
      var elem = this.$refs["remain"];
      let x = parseInt(this.myObj.remainingLeafs);
      if (isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        this.myObj.remainingLeafs = x;
        return (elem.state = true);
      }
    },
    CheckLast() {
      var elem = this.$refs["last"];
      let x = parseInt(this.myObj.lastIssued);
      if (isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        this.myObj.lastIssued = x;
        return (elem.state = true);
      }
    },
    async Add() {
      this.CheckAccount();
      this.CheckStart();
      this.CheckEnd();
      this.CheckTotal();
      this.CheckRemain();
      this.CheckLast();
      if (
        this.CheckAccount() == false ||
        this.CheckStart() == false ||
        this.CheckEnd() == false ||
        this.CheckTotal() == false ||
        this.CheckRemain() == false ||
        this.CheckLast() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (this.myObj.start >= this.myObj.end) {
        this.$bvToast.toast("Invalid cheque no. values entered", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        console.log(this.myObj);
        this.visibility = false;
        // this.request = true;
        // var status = await this.post({
        //   url:
        //     this.$store.state.domain +
        //     "chequebook/save?db=" +
        //     this.$store.state.userData.db +
        //     "&cID=" +
        //     this.$store.state.userData.cId,
        //   body: this.myObj,
        //   message: "Cheque Book saved successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        //   showError: true,
        // });
        // this.request = false;
        // if (status) {
        //   this.LoadData();
        //   this.visibility = false;
        // }
      }
    },
    async Delete(id) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        // var status = await this.delete({
        //   url:
        //     this.$store.state.domain +
        //     "chequebook/" +
        //     id +
        //     "?db=" +
        //     this.$store.state.userData.db,
        //   message: "Cheque Book deleted successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        // if (status) this.LoadData();
      }
    },
    async LoadAccounts() {
      var obj = {
        url:
          this.$store.state.domain +
          "Accounts/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.accList = await this.get(obj);
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "chequebook/Loaddata?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);

      this.dataLoading = false;
    },
  },
};
</script>
<style scoped>
.media {
  align-items: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
